export const getSortOptions = (prefs, labels) => {
	const { sorts = '', sortsTitles = '' } = prefs;
	const titleToSortValueMap = new Map();
	const options = [];

	const sortValuesArray = sorts.split(',');
	const sortTitlesArray = sortsTitles.split(',');

	if (sortValuesArray.length === sortTitlesArray.length) {
		sortTitlesArray.forEach((entry, index) => {
			titleToSortValueMap.set(entry, sortValuesArray[index]);
		});

		titleToSortValueMap.forEach((sortValue, title) => {
			let sortAsc;
			let sortDesc;
			const sortLabel = labels.get(title).trim().replace(/[\\"]/g, '');

			if (
				sortValue.toLowerCase().includes('color') ||
				sortValue.toLowerCase().includes('bodystyle')
			) {
				sortAsc = labels.get('A_TO_Z');
				sortDesc = labels.get('Z_TO_A');
			} else if (sortValue.includes('year')) {
				sortAsc = labels.get('OLD_TO_NEW');
				sortDesc = labels.get('NEW_TO_OLD');
			} else if (sortValue.includes('inventoryDate')) {
				sortAsc = labels.get('EARLIEST');
			} else if (sortValue.includes('geodist')) {
				sortAsc = labels.get('NEAR_TO_FAR');
				sortDesc = labels.get('FAR_TO_NEAR');
			} else if (sortValue.includes('relevancyScore')) {
				sortDesc = labels.get('RELEVANCE');
			} else {
				sortAsc = labels.get('LOW_TO_HIGH');
				sortDesc = labels.get('HIGH_TO_LOW');
			}

			switch (sortValue) {
				case 'BLANK':
					options.push({
						value: '',
						label: `${sortLabel}`
					});
					break;
				case 'relevancyScore':
					options.push({
						value: `${sortValue} desc`,
						label: `${sortLabel}`
					});
					break;
				case 'inventoryDate':
					options.push({
						value: `${sortValue} asc`,
						label: `${sortLabel}: ${sortAsc}`
					});
					break;
				default:
					options.push({
						value: `${sortValue} asc`,
						label: `${sortLabel}: ${sortAsc}`
					});
					options.push({
						value: `${sortValue} desc`,
						label: `${sortLabel}: ${sortDesc}`
					});
			}
		});
	}
	return options;
};
